import axios from '@/utils/http'
// -----------------企业中心------------

// 新曾员工
export const add = (datavalue) => {
    return axios.request({
        url: '/payment/employee/add',
        data: datavalue,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// `/payment/role/${!params.dataid ? 'save' : 'update'}`
export const addup = (datavalue) => {
    return axios.request({
        url: `/payment/employee/${!datavalue.id ? 'add' : 'update'}`,
        data: datavalue,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 员工列表
export const list = (datavalue) => {
    return axios.request({
        url: '/payment/employee/list',
        data: datavalue,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 获取员工角色集合
export const employeeRoleIds = (datavalue) => {
    return axios.request({
        url: '/payment/employee/get-employeeRoleIds',
        params: datavalue,
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 员工分配角色
export const assign = (datavalue) => {
    return axios.request({
        url: '/payment/role/sonRoles',
        data: datavalue,
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 获取当前登录人角色和子角色
export const sonRoles = (datavalue) => {
    return axios.request({
        url: '/payment/role/sonRoles',
        params: datavalue,
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//角色信息
export const roleInfo = (datavalue) => {
    return axios.request({
        url: '/payment/role/info',
        params: datavalue,
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 保存
export const dataFormSubmit = (params) => {
    return axios.request({
        url: `/payment/role/${!params.dataid ? 'save' : 'update'}`,
        data: params,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 角色列表（只可看到本角色和子角色）
export const getDataListd = (datavalue) => {
    return axios.request({
        url: '/payment/role/sub-list',
        data: datavalue,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 删除角色）
export const deleateHandle = (params) => {
    return axios.request({
        url: '/payment/role/delete',
        data: params,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//分配角色提交按钮
export const updateRole = (params) => {
    return axios.request({
        url: '/payment/employee/updateRole',
        data: params,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//删除@批量删除
export const deleate = (params) => {
    return axios.request({
        url: '/payment/employee/delete',
        data: params,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//编辑，获取员工信息
export const information = (params) => {
    return axios.request({
        url: `/payment/employee/${params.employeeId}`,
        params: "",
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

