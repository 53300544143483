<template>
  <div>
     <div class="title"> <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>新增员工账号</div>
     <el-card class="main">
       <div class="box">
          <p>员工基本信息</p>
          <p>注：带有“*”必填项</p>
        </div>
       <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
         <el-form-item label="员工姓名" prop="name">
          <el-input v-model="ruleForm.name" class="widthinp"></el-input>
        </el-form-item>
         <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="ruleForm.sex">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input v-model="ruleForm.mobile" class="widthinp"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="ruleForm.email" class="widthinp"></el-input>
        </el-form-item>
         <el-form-item label="备注" >
            <el-input type="textarea" v-model="ruleForm.remark" style="width:600px"></el-input>
        </el-form-item>
       
        <el-form-item style="text-align:center;margin-top:50px">
          <el-button type="primary" @click="submitForm('ruleForm')" :loading="load">确定</el-button>
           <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
     </el-card>
  </div>
</template>

<script>
import {add,information,addup} from  "@/api/enterprise.js";
import {isEmail,isMobile} from "@/utils/validate.js"
export default {
  data() {
     var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error("邮箱格式错误"));
      } else {
        callback();
      }
    };
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      load:false,
       ruleForm: {
          name: '',
          sex: '',
          mobile: '',
          email: '',
          remark: '',
        },
         rules: {
          name: [
            { required: true, message: '请输入员工姓名', trigger: 'blur' },
            { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
          ],
           sex: [
            { required: true, message: '请选择性别', trigger: 'change' }
          ],
          mobile: [
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            { validator: validateMobile, trigger: "blur" }
          ],
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            { validator: validateEmail, trigger: "blur"}
          ],            
        },
    };
    
  },
  mounted(){
    if (this.$route.query.id) {
       this.informat()
    }
  },
  methods: {
      submitForm(formName) {
        console.log(this.ruleForm.sex,"e");
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.load=true
            addup(this.ruleForm).then((data=>{
               this.load=false
             if (data.code==200) {
               if (this.$route.query.id) {
                 var  msg="修改成功"
               }else{
                 var  msg="创建成功"
               }
              
               this.$message({
                    message: msg,
                    type: 'success'
                });
                this.$router.push('enterprise-jurisdiction') 
              }
            }))
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
       //获取员工信息
    informat(){
      let params = {
        employeeId:this.$route.query.id
      }
      information(params).then((data) => {
        if(data.code == 200) {
          console.log(data);
          this.ruleForm = data.data
          this.ruleForm.sex = String(data.data.sex)
        }
      })
    },
    }
};
</script>

<style  scoped>
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
     color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
}
.main{
    margin-top: 10px;
}
.demo-ruleForm{
  margin-top: 20px;
  margin-left: 100px;
  width: 80%;
}
.box{
   margin-left: 100px;
  height: 40px;
  font-size: 14px;
  /* background: darkcyan; */
  border-bottom: 1px solid darkgrey;
}
.box p{
  
  display: inline-block;
  /* float: left; */
}
.box p:nth-child(1){
 margin-right: 80%;
}
.box p:nth-child(2){
 color: gray;
}
.widthinp{
  width: 300px;
}
</style>


